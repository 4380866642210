/**
 * ArgoService - 2/01/2020 - By Antonello Bottero
 * Restituisce i parametri get che si trovano nell'url
 *
 * @param paramName
 * @returns {[]}
 */
function getUrlParams(paramName = false) {
    var urlArr= window.location.href.split('?');
    var urlParams = [];

    // se l'url contiene parametri get procedo ad estrapolarli
    if(typeof urlArr[1] !== "undefined") {
        var parts = urlArr[1].replace(/([^=&]+)=([^&]*)/gi, function (m, key, value) {
            if(/^\d*$/.test(value))
                value = Number(value); // se il valore é composto da sole cifre lo converto in Number

            // se in input é specificato un nome restituisco un array monodimensionale con tutte le corrispondenze
            // se nessun nome é specificato ogni valore sarà salvato in un oggetto con attributi key e value
            if (paramName) {
                if(key==paramName || key==paramName+'[]')
                    urlParams.push(value);
            }
            else{
                urlParams.push({
                    key: key,
                    value: value
                });
            }
        });
    }

    return urlParams;
}

/**
 * ArgoService - 29/01/2020 - By Antonello Bottero
 * Dati i parametri in input, costruisce un nuovo url prendendo la location base e accodando i parametri
 *
 * @param urlParams
 * @returns {string}
 */
function buildUrlWithParams(urlParams){
    var urlArr = window.location.href.split('?');
    var urlWithParams = urlArr[0];
    if(Array.isArray(urlParams) && urlParams.length>0){
        urlWithParams += '?';
        for (var k in urlParams) {
            urlWithParams += urlParams[k].key+'='+urlParams[k].value+'&';
        }
    }

    return urlWithParams;
}