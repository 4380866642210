var bulletin = function(bulletinCrud) {

    this.bindEvents = function() {
        $('[data-interact=generate_code]').unbind('click').bind('click', function () {
            app.block(1);
            $.post(app.baseUrl + '/bulletin/get_new_code', {classification: $('#classification').val(), language: $('#language_id').val()})
                .done(function (data) {
                    if (data.response) {
                        $('#code').val(data.message);
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .fail(function () {
                    console.error("Errore Ajax!");
                    app.error("", "Errore Ajax!");
                    app.block(0);
                });
        });


        var drop = $("[data-interact=bulletin_media_id]").dropzone({
            autoProcessQueue: false,
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $(this).attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $(this).attr('data-max'),
            acceptedFiles: '.pdf,.docx',
            init: function () {
                var size = $(this).attr('data-size');
                var name = $(this).attr('data-name');

                var self = this;
                if ($.trim(size) !== "") {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, app.baseUrl + "/media/" + $(this).attr('data-id') + "/download");
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/media/" + $("#media_id").attr('data-id') + "?bulletin_id=" + $("#media_id").attr('data-bulletinid'))
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                } else {
                    $(document).find(file.previewElement).remove();
                }
            },
            maxfilesexceeded: function (file) {
                app.warning("", T.uploadMaxFilesExceeded);
                this.removeFile(file);
            }
        });

        if (bulletinCrud) {
            bulletinCrud.getCustomFormData = function (data) {
                bulletin_media = bulletin_handler.retrieve_drop_data();
                for (var i = 0; i < bulletin_media.length; i++) {
                    for (var j = 0; j < bulletin_media[i].files.length; j++) {
                        data.append(bulletin_media[i].name, bulletin_media[i].files[j][0], bulletin_media[i].files[j][1]);
                    }
                }

                data.append('subjects', subject_selector.get_selected_subjects());

                return data;
            };
        }
    };

    this.bindEvents();
};
