var flashCommunication = function(flashCommunicationCrud) {

    this.bindEvents = function() {
        if (flashCommunicationCrud) {
            flashCommunicationCrud.getCustomFormData = function (data) {
                var subjects = subject_selector.get_selected_subjects();
                data.append('subjects', subjects);

                return data;
            };
        }

    };

    this.bindEvents();
};
