var technicalDocument = function(technicalDocumentCrud) {

    this.bindEvents = function() {
        if (technicalDocumentCrud) {
            technicalDocumentCrud.getCustomFormData = function (data) {
                var technical_document_media = technical_document_handler.retrieve_drop_data();
                for (var i = 0; i < technical_document_media.length; i++) {
                    for (var j = 0; j < technical_document_media[i].files.length; j++) {
                        data.append(technical_document_media[i].name, technical_document_media[i].files[j][0], technical_document_media[i].files[j][1]);
                    }
                }
                return data;
            };
        }

    };

    this.bindEvents();
};
