var news = function(newsCrud) {

    this.bindEvents = function() {
        tinymce.init({
            selector: "textarea",
            theme: "modern",
            height: 300,
            language: 'it',
            paste_data_images: true,
            plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace wordcount visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "template paste textcolor colorpicker textpattern"
            ],
            toolbar1: "insertfile undo redo | styleselect | bold italic | forecolor backcolor fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
            //toolbar2: "preview ",
            menubar: false,
            fontsize_formats: '11px 17px 22px',
            init_instance_callback: function (editor) {
                editor.on('Change', function (e) {
                    $("#" + editor.id).val(editor.getContent());
                });
            },
            setup : function(ed)
            {
                ed.on('init', function()
                {
                    this.getDoc().body.style.fontSize = '17px';
                });
            }
        });

        var drop = $("#gallery").dropzone({
            autoProcessQueue: false,
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#gallery").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 20,
            clickable: true,
            maxFilesize: $("#gallery").attr('data-max'),
            acceptedFiles: '.jpg,.png,.pdf,.docx,.doc,.xls,.xlsx',
            init: function () {
                var medias = JSON.parse($("#medias").val());
                var self = this;
                $.each(medias, function () {
                    if (typeof this.id !== 'undefined') {
                        // oggetto file per dropzone
                        var file = {
                            name: this.filename,
                            size: this.size,
                            status: 'success',
                            id: this.id,
                            accepted: true
                        };

                        // fingo di aggiungere un nuovo documento a dropzone
                        self.emit("addedfile", file);
                        self.createThumbnailFromUrl(file, app.baseUrl + "/media/" + this.id + "/download");
                        self.emit("complete", file);

                        // aggiungo il mio file all'array
                        self.files.push(file);
                    }
                });
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/media/" + file.id)
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                } else {
                    $(document).find(file.previewElement).remove();
                }
            },
            maxfilesexceeded: function (file) {
                app.warning("", T.uploadMaxFilesExceeded);
                this.removeFile(file);
            }
        });

        if (newsCrud) {
            newsCrud.getCustomFormData = function (data) {
                if (typeof (drop[0]) != "undefined" && drop[0] !== null) {
                    for (var j = 0; j < drop[0].dropzone.files.length; j++) {
                        if (drop[0].dropzone.files[j].status === 'queued') {
                            data.append('gallery_' + j, drop[0].dropzone.files[j], drop[0].dropzone.files[j].name);
                        }
                    }
                }

                var subjects = subject_selector.get_selected_subjects();
                data.append('subjects', subjects);

                return data;
            };
        }

    };

    this.bindEvents();
};
