var invoices = function(invoicesCrud) {

    this.bindEvents = function() {
        var drop = $("#media_id").dropzone({
            autoProcessQueue: false,
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#media_id").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $("#media_id").attr('data-max'),
            acceptedFiles: '.pdf',
            init: function () {
                var size = $("#media_id").attr('data-size');
                var name = $("#media_id").attr('data-name');

                var self = this;
                if ($.trim(size) !== "") {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, app.baseUrl + "/media/" + $("#media_id").attr('data-id') + "/download");
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/media/" + $("#media_id").attr('data-id'))
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                } else {
                    $(document).find(file.previewElement).remove();
                }
            },
            maxfilesexceeded: function (file) {
                app.warning("", T.uploadMaxFilesExceeded);
                this.removeFile(file);
            }
        });

        if (invoicesCrud) {
            invoicesCrud.getCustomFormData = function (data) {
                if (typeof (drop[0]) != "undefined" && drop[0] !== null) {
                    for (var j = 0; j < drop[0].dropzone.files.length; j++) {
                        if (drop[0].dropzone.files[j].status === 'queued') {
                            data.append('media_id', drop[0].dropzone.files[j], drop[0].dropzone.files[j].name);
                        }
                    }
                }

                return data;
            };
        }

    };

    this.bindEvents();
};
